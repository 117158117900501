@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  @apply invisible;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
